.query-builder-container {
    input[type="number"],
    input[type="text"],
    input[type="date"],
    select {
        @extend .form-control;
        width: unset;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    input[type="radio"] {
        margin-left: 10px;
        margin-right: 5px;
    }

    button {
        @extend .btn;
        @extend .btn-primary;
        height: 32px;
        margin-right: 5px;
    }

    .group--actions button:nth-child(3),
    .rule--header button {
        @extend .btn-danger;
    }

    .group--conjunctions input {
        margin-left: 8px;
    }
}

.rule_group .rule_group--children {
    display: grid;
}

.group-or-rule-container.group-container > .group.rule_group.group-or-rule {
    overflow-y: hidden;
}