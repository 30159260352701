html {
  scroll-behavior: smooth;
}

.pure-material-textfield-outlined {
    --pure-material-safari-helper1: #8ad4ee;
    position: relative;
    display: inline-block;
    padding-top: 6px;
    font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
    font-size: 16px;
    line-height: 1.5;
    overflow: hidden;
    width: 100%;
}

/* Input, Textarea */
.pure-material-textfield-outlined > input,
.pure-material-textfield-outlined > textarea {
  box-sizing: border-box;
  margin: 0;
  border: solid 1px; /* Safari */
  border-color: #e4e7ea;
  border-top-color: transparent;
  border-radius: 4px;
  padding: 15px 13px 15px;
  width: 100%;
  height: 35px;
  /* color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87); */
  background-color: transparent;
  box-shadow: none; /* Firefox */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  caret-color: #8ad4ee;
  transition: border 0.2s, box-shadow 0.2s;
}

/* Span */
.pure-material-textfield-outlined > input + span,
.pure-material-textfield-outlined > textarea + span {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    border-color: #e4e7ea;
    width: 100%;
    max-height: 100%;
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
    font-size: 75%;
    line-height: 15px;
    cursor: text;
    transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}

/* Corners */
.pure-material-textfield-outlined > input + span::before,
.pure-material-textfield-outlined > input + span::after,
.pure-material-textfield-outlined > textarea + span::before,
.pure-material-textfield-outlined > textarea + span::after {
  content: "";
  display: block;
  box-sizing: border-box;
  margin-top: 6px;
  border-top: solid 1px;
  border-top-color: #e4e7ea;
  min-width: 10px;
  height: 8px;
  pointer-events: none;
  box-shadow: inset 0 1px transparent;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.pure-material-textfield-outlined > input + span::before,
.pure-material-textfield-outlined > textarea + span::before {
    margin-right: 4px;
    border-left: solid 1px transparent;
    border-radius: 4px 0;
}

.pure-material-textfield-outlined > input + span::after,
.pure-material-textfield-outlined > textarea + span::after {
    flex-grow: 1;
    margin-left: 4px;
    border-right: solid 1px transparent;
    border-radius: 0 4px;
}

/* Hover */
.pure-material-textfield-outlined:hover > input,
.pure-material-textfield-outlined:hover > textarea {
    /* border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87); */
    border-top-color: transparent;
}

/* .pure-material-textfield-outlined:hover > input + span::before,
.pure-material-textfield-outlined:hover > textarea + span::before,
.pure-material-textfield-outlined:hover > input + span::after,
.pure-material-textfield-outlined:hover > textarea + span::after {
  border-top-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
}

.pure-material-textfield-outlined:hover > input:not(:focus):placeholder-shown,
.pure-material-textfield-outlined:hover > textarea:not(:focus):placeholder-shown {
  border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
} */

/* Placeholder-shown */
.pure-material-textfield-outlined > input:not(:focus):placeholder-shown,
.pure-material-textfield-outlined > textarea:not(:focus):placeholder-shown {
    border-top-color: #e4e7ea;
}

.pure-material-textfield-outlined > input:not(:focus):placeholder-shown + span,
.pure-material-textfield-outlined > textarea:not(:focus):placeholder-shown + span {
    font-size: 0.875rem;
    line-height: 46px;
}

.pure-material-textfield-outlined > input:not(:focus):placeholder-shown + span::before,
.pure-material-textfield-outlined > textarea:not(:focus):placeholder-shown + span::before,
.pure-material-textfield-outlined > input:not(:focus):placeholder-shown + span::after,
.pure-material-textfield-outlined > textarea:not(:focus):placeholder-shown + span::after {
    border-top-color: transparent;
}

/* Focus */
.pure-material-textfield-outlined > input:focus,
.pure-material-textfield-outlined > textarea:focus {
    border-color: #8ad4ee;
    border-top-color: transparent;
    box-shadow: inset 1px 0 var(--pure-material-safari-helper1), inset -1px 0 var(--pure-material-safari-helper1),
        inset 0 -1px var(--pure-material-safari-helper1);
    outline: none;
}

.pure-material-textfield-outlined > input:focus + span,
.pure-material-textfield-outlined > textarea:focus + span {
    color: #8ad4ee;
}

.pure-material-textfield-outlined > input:focus + span::before,
.pure-material-textfield-outlined > input:focus + span::after,
.pure-material-textfield-outlined > textarea:focus + span::before,
.pure-material-textfield-outlined > textarea:focus + span::after {
    border-top-color: var(--pure-material-safari-helper1) !important;
    box-shadow: inset 0 1px var(--pure-material-safari-helper1);
}

/* Disabled */
.pure-material-textfield-outlined > input:disabled,
.pure-material-textfield-outlined > input:disabled + span,
.pure-material-textfield-outlined > textarea:disabled,
.pure-material-textfield-outlined > textarea:disabled + span {
    border-color: #8ad4ee !important;
    border-top-color: transparent !important;
    color: #8ad4ee;
    pointer-events: none;
}

.pure-material-textfield-outlined > input:disabled + span::before,
.pure-material-textfield-outlined > input:disabled + span::after,
.pure-material-textfield-outlined > textarea:disabled + span::before,
.pure-material-textfield-outlined > textarea:disabled + span::after {
    border-top-color: #8ad4ee !important;
}

.pure-material-textfield-outlined > input:disabled:placeholder-shown,
.pure-material-textfield-outlined > input:disabled:placeholder-shown + span,
.pure-material-textfield-outlined > textarea:disabled:placeholder-shown,
.pure-material-textfield-outlined > textarea:disabled:placeholder-shown + span {
    border-top-color: #8ad4ee !important;
}

.pure-material-textfield-outlined > input:disabled:placeholder-shown + span::before,
.pure-material-textfield-outlined > input:disabled:placeholder-shown + span::after,
.pure-material-textfield-outlined > textarea:disabled:placeholder-shown + span::before,
.pure-material-textfield-outlined > textarea:disabled:placeholder-shown + span::after {
    border-top-color: transparent !important;
}

/* Faster transition in Safari for less noticable fractional font-size issue */
@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        .pure-material-textfield-outlined > input,
        .pure-material-textfield-outlined > input + span,
        .pure-material-textfield-outlined > textarea,
        .pure-material-textfield-outlined > textarea + span,
        .pure-material-textfield-outlined > input + span::before,
        .pure-material-textfield-outlined > input + span::after,
        .pure-material-textfield-outlined > textarea + span::before,
        .pure-material-textfield-outlined > textarea + span::after {
            transition-duration: 0.1s;
        }
    }
}

.ag-header-cell-label .ag-header-cell-text {
    font-size: 14px;
}
.ag-header-cell-resize {
  height: 80% !important;
  border-left: 1px solid #bdc3c7 !important;
}

.ag-ltr .ag-header-cell-resize {
    right: -7px !important;
}
.ag-ltr .ag-pinned-right-header .ag-header-cell-resize {
    left: -7px !important;
}

.ag-rtl .ag-header-cell-resize {
    left: -7px !important;
}
.ag-rtl .ag-pinned-left-header .ag-header-cell-resize {
    right: -7px !important;
}
.rc-time-picker-input {
    width: "100%";
    height: 36px !important;
}
.rc-time-picker-input:focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}
.rc-time-picker-clear-icon:hover:after {
    color: rgba(32, 168, 216, 0.25);
}

.rc-time-picker-clear-icon:after {
    font-size: 16px !important;
    margin-top: 6px !important;
}

.ck-editor__editable {
  min-height: 350px !important;
}

.ag-header-cell-resize {
  z-index: inherit !important;
}

.DayPickerInput {
  min-width: 187px;
}