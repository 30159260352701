.sidebar {
    .nav-item {
        width: 200px;
    }
    .nav-title {
        width: 100%;
        word-wrap: break-word;
    }
    .nav-link {
        width: 100%;
        word-wrap: break-word;
    }
    .nav-dropdown > .nav-dropdown-items {
        overflow: hidden;

        .nav-item {
            padding-left: 12px;
        }
    }
}

.sidebar-minimized {
    .sidebar {
        .nav-dropdown-items {
            .nav-item {
                .nav-link {
                    white-space: normal;
                }
            }
        }
    }
}

input[disabled][type]:not([type="hidden"]),
input[read-only][type]:not([type="hidden"]),
select[readonly],
div.Select.is-disabled > div.Select-control {
    @extend .form-control:disabled;
}

.disable-all-childs {
    & .Select-input input {
        background-color: unset !important;
    }

    .react-toggle {
        @extend .disable-user-selection;

        .react-toggle-thumb {
            border-color: gray;
        }
    }

    input,
    textarea,
    .Select-control,
    .react-toggle .react-toggle-track/* ,
    .react-json-view */ {
        @extend .disable-user-selection;
        @extend .form-control:disabled;
        /* filter: brightness(95%); */
    }
}

.disable-user-selection {
    cursor: default !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    pointer-events: none !important;
}

div.dropdown.open > ul.dropdown-menu /* > li[data-autosuggest_suggestion] */ {
    z-index: 1050 !important;
}

.dropdown-menu {
    padding: 0 0 !important;

    .dropdown-header {
        border-radius: 4px 4px 0px 0px;
    }
}

.nav-language-dropdown {
    max-height: 70vh;
    overflow: overlay;
}

.nav-notification-menu {
    max-width: 500;
}

.nav-tenant-dropdown {
    overflow: auto;
    padding: 0;
    min-width: 250;
    min-height: 142;
    max-height: 70vh;
}

.ql-editor {
    min-height: 200px;
    max-height: 60vh;
}