.rc-cascader {
    font-size: 12px;
    &-menus {
        font-size: 12px;
        overflow: hidden;
        background: #fff;
        position: absolute;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
        white-space: nowrap;

        &-hidden {
            display: none;
        }
    }
    &-menu {
        display: inline-block;
        width: 160px;
        //height: 192px;
        max-height: 192px;
        list-style: none;
        margin: 0;
        padding: 0;
        border-right: 1px solid #e9e9e9;
        overflow: auto;
        vertical-align: top;
        &:last-child {
            border-right: 0;
        }
    }
    &-menu-item {
        height: 32px;
        line-height: 32px;
        padding: 0 16px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 0.3s ease;
        position: relative;
        &:hover {
            background: rgba(158, 207, 250, 0.35);
        }
        &-disabled {
            cursor: not-allowed;
            color: #ccc;
            &:hover {
                background: transparent;
            }
        }
        &-loading:after {
            position: absolute;
            right: 12px;
            content: "loading";
            color: #aaa;
            font-style: italic;
        }
        &-active {
            background: rgba(158, 207, 250, 0.15);
            &:hover {
                background: rgba(158, 207, 250, 0.15);
            }
        }
        &-expand {
            position: relative;
            &-icon {
                font-size: 12px;
                color: #999;
                position: absolute;
                right: 16px;
                line-height: 32px;
            }
        }
    }
}
