@import "../node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "../node_modules/ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin.scss";

.ag-theme-balham {
    @include ag-theme-balham(
        (
            header-column-separator: false,
            header-column-resize-handle: true,
            header-column-resize-handle-height: 100%,
            header-column-resize-handle-width: 20px,
            header-column-resize-handle-color: ag-derived(border-color, $opacity: 0),
        )
    );

    .ag-header-cell-resize {
        height: 100% !important;
    }
}